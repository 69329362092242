.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bg_white {
  background-color: white;
}
.bg_color {
  background-color: rgb(182, 247, 109);
}

.bg_red {
  background-color: #da607a;
}

.bg_dark {
  background-color: #434a50;
  color: white;
}

.bookbutton_bigscreen {
  overflow:visible;
  font-size: 11px; 
  height: 25px;
  
}
.bookbutton {
  overflow:visible;
}
.bookitem {
  position: relative;
  z-index: 0;
}

.bookitem_popover {
  overflow:visible;
  position: relative;
  z-index: 5;
}


.bookList {
  background-color: #212529;
  position: sticky;
  overflow: visible;
  bottom: 0;
  height: 20vh;
  z-index: 3;
}
.bookList-manage {
  background-color: #212529;
  position: sticky;
  overflow: visible;
  bottom: 0;
  height: 20vh;
  
}

.editWindowClosed {
  height: 85vh;
  overflow-y: scroll;
}

.editWindowOpened {
  height: 65vh;
  overflow-y: scroll;
}

.bookWindowClosed {
  height: 77vh;
  overflow-y: scroll;
}
.bookWindowOpened {
  height: 57vh;
  overflow-y: scroll;
}

.calc_fee {
  text-align: end;
  font-size: 1.5vh;
}
.calc_align_start {
  text-align: start;
  font-size: 1.5vh;
}
.calc_fee_total {
  text-align: end;
  font-weight: bold;
  background-color: #212529;
  color: whitesmoke;
  font-size: 1.5vh;
}

.calc_frame {
  overflow-y: scroll;
}

.calc_info {
  white-space: nowrap;
  display: inline-block;
  text-align: start;
  font-weight: bold;
}

.calc_method {
  text-align: end;
  font-size: 1.4vh;
  white-space: nowrap;
  display: inline-block;
}

.calc_user {
  text-align: start;
  font-weight: bold;

  font-size: 1.5vh;
}

.clock {
  max-width: 110px;
  min-width: 110px;
  height: 25px;
  padding-top: 0px;
}



.clock_text {
  font-size: 9px;
  font-weight: bold;
  text-align: right;
  vertical-align: top;
}

.clock_text_right {
  font-size: 9px;
  font-weight: bold;
  text-align: left;
  vertical-align: top;
}

.container_no_margin {
  width: fit-content;
  margin : 0px;
  padding : 0px;
  border: 0px;
}

.editingbookinfo {
  white-space: nowrap;
  display: inline-block;
}
.feeinfo {
  white-space: nowrap;
  display: inline-block;
}

@media screen and (max-width:666px) and (orientation: portrait) {
  .landscape {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 88vh;
    height: 100vw;
    overflow-x: auto;
    position: absolute;
    top: 100%;
    left: 0;
  }
}


.marquee {
  width: 340px;
  line-height: 15px;
  margin: auto;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}
.marquee p {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 15s linear infinite;
}




.memberitem {
  white-space: nowrap;
}

.overflowed {
  overflow: visible;
  position: relative;
 
}

.payinfo {
  white-space: nowrap;
  display: inline-block;
}
.room {
  font-size: 13px;
  height: 8px;
}

.roomImage {
  width: 340px;
  margin: auto;
}
.roomName {
  position: sticky;
  top:0;
}

.customDatePickerWidth, 
.customDatePickerWidth > div.react-datepicker-wrapper, 
.customDatePickerWidth > div > div.react-datepicker__input-container
.customDatePickerWidth > div > div.react-datepicker__input-container input {
  width: 104px;
}

/* timeline = 스케쥴표에 가로선 긋는 css임 */
.timeline {
  display: flex;
  flex-basis: 100%;
  color: black;
  align-items: center;
  font-size: 2.4vh;
  margin: 0px 10px;
  font-weight: bold;
  white-space: nowrap;
}
.timeline::after {
  content: "";
  flex-grow: 1;
  background: rgba(0,0,0,0.45);
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 2px;
}

.timeselector {
  width: 100px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes marquee {
  0%   { transform: translate(0, 0); }
  100% { transform: translate(-100%, 0); }
}